<template>
  <v-container class="tw-py-2 md:tw-py-5">
    <div>
      <div class="tw-px-0 md:tw-px-6 tw-mt-2">
        <div class="tw-px-0 md:tw-px-6">
          <h2 class="tw-text-xl c-blue-text tw-font-bold">
            Garage: {{ scanObject(garageInfo, 'garage.name') }}
          </h2>

          <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
            Client Details
          </h2>
          <hr class="tw-mb-2 md:tw-mb-5" />
          <div
            class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-0 tw-gap-8"
          >
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Assessment No.</span>
                <span class="c-gray-text">{{ bookingId }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Claim No.</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.claimNumber')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Insured Name</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'clientInfo.name')
                }}</span>
              </div>
            </div>
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Date Created</span>
                <span class="c-gray-text">{{
                  booking.createdAt | formatToHuman
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Policy No.</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'policyInfo.policyNumber')
                }}</span>
              </div>
            </div>
          </div>
          <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
            Vehicle Details
          </h2>
          <hr class="tw-mb-2 md:tw-mb-5" />
          <div
            class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-0 tw-gap-8"
          >
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Registration No.</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'vehicleInfo.registrationNumber')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Vehicle Color</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.color')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Engine No.</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.engineNumber')
                }}</span>
              </div>
            </div>
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Make</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.make')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">YOM</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.yearOfManufacture')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Chassis No.</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.chassisNumber')
                }}</span>
              </div>
            </div>
            <div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Model</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.model')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Vehicle Type</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.bodyType')
                }}</span>
              </div>
              <div class="tw-grid tw-grid-cols-2 tw-flex tw-items-center">
                <span class="c-gray-text tw-font-bold">Mileage</span>
                <span class="c-gray-text">{{
                  scanObject(booking, 'biddingForm.mileage')
                }}</span>
              </div>
            </div>
          </div>
          <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
            {{ isGarage ? 'Garage Bid Report' : 'Assessment Report' }}
          </h2>
          <hr class="tw-mb-2 md:tw-mb-5" />
          <p>
            From the garage bid reports on the Vehicle please confirm the
            estimated prices of vehicle parts before repairs and quantity
            required, grade and quality of the parts as well as their prices.'
            Report
          </p>
          <h2 class="my-2">Submitted Quotes:</h2>
          <div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th scope="col" class="text-left">Parts/Services</th>
                    <th scope="col" class="text-left">Type</th>
                    <th scope="col" class="text-left">Quantity</th>
                    <th scope="col" class="text-left">Unit Price</th>
                    <th scope="col" class="text-left">Total</th>
                    <th scope="col" class="text-left">VAT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in parts" :key="item.name">
                    <td>{{ item.part }}</td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.unitPrice | formatCurrency }}</td>
                    <td>{{ getTotal(item) | formatCurrency }}</td>
                    <td>
                      <v-switch v-model="item.vat" disabled></v-switch>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <hr />
            <div
              class="tw-flex tw-justify-start md:tw-justify-end tw-py-2 md:tw-py-4"
            >
              <div class="tw-w-full md:tw-w-1/3">
                <div class="tw-grid tw-grid-cols-2 c-gray-text">
                  <h6>Net Total</h6>
                  <h6>Ksh. {{ netTotal | formatCurrency }}</h6>
                </div>
                <div class="tw-grid tw-grid-cols-2 c-gray-text">
                  <h6>VAT 16%</h6>
                  <h6>Ksh. {{ vatTotal | formatCurrency }}</h6>
                </div>
                <div
                  v-if="contributionTotal > 0"
                  class="tw-grid tw-grid-cols-2 c-gray-text"
                >
                  <h6>Client contribution</h6>
                  <h6>(Ksh. {{ contributionTotal | formatCurrency }})</h6>
                </div>
                <div
                  class="tw-grid tw-grid-cols-2 c-gray-text tw-text-xl md:tw-text-2xl"
                >
                  <h6>TOTAL</h6>
                  <h6>Ksh. {{ grandTotal | formatCurrency }}</h6>
                </div>
              </div>
            </div>
<div v-if="scanObject(booking, 'biddingForm.analystComment')">
  <hr />
  <h2 class="tw-text-xl tw-mt-2 c-gray-text tw-mt-3 md:tw-mt-6">
    Assessment Remark
  </h2>
  <hr class="tw-mb-2 md:tw-mb-5" />
  <p
      v-html="scanObject(booking, 'biddingForm.analystComment')"
  ></p>
</div>
          </div>
          <hr />
          <div>
            <div
              v-if="isGarage && !scanObject(garageInfo, 'winningBid')"
              class="tw-mt-2 pt-5 md:tw-mt-0"
            >
              <v-textarea
                label="Enter your remarks"
                v-model="remarks"
                required
                outlined
                clearable
                rows="3"
                class="tw-mb-4"
                :rules="[(v) => !!v || 'Remarks are required']"
              ></v-textarea>

              <div
                class="tw-flex tw-flex-col md:tw-flex-row tw-items-start md:tw-items-center tw-gap-0 md:tw-gap-10"
              >
                <div class="tw-w-full sm:tw-w-60">
                  <v-select
                    label="Select Claim Analyst"
                    v-model="claimAnalyst"
                    :items="claimAnalysts"
                    item-text="full_name"
                    return-object
                  ></v-select>
                </div>

                <v-checkbox
                  v-model="confirmGarage"
                  label="Approve Repair"
                ></v-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isGarage && !scanObject(garageInfo, 'winningBid')"
          class="tw-flex tw-justify-center"
        >
          <div
            class="tw-w-2/3 md:tw-w-1/3 tw-py-4 md:tw-py-10 tw-space-x-2 tw-flex tw-justify-center"
          >
            <v-btn
              color="warning"
              :loading="submitting"
              block
              @click="assignBid"
              :disabled="canAuthorize"
              >Finish</v-btn
            >
          </div>
        </div>
        <div v-else>
          <v-checkbox
            v-model="confirmApprover"
            label="Approve Repair"
          ></v-checkbox>
          <div
            class="tw-w-2/3 md:tw-w-1/3 tw-py-4 md:tw-py-6 tw-space-x-2 tw-flex tw-justify-center"
          >
            <v-btn
              color="warning"
              :loading="approving"
              :disabled="!confirmApprover"
              block
              @click="$emit('authorize-bid')"
              >Authorize</v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { formatCurrency } from '@/utils/helpers'
import { VAT } from '@/utils/const'
import Validations from '@/utils/Validations'
import { mapState } from 'vuex'

import { formatToHuman } from '@/utils/time'
import biddingFormMixin from '@/mixins/biddingFormMixin'

export default {
  name: 'garageBidTemplate',
  props: {
    booking: {
      required: true,
      type: Object,
    },
    garageInfo: {
      required: true,
      type: Object,
    },
    isGarage: {
      type: Boolean,
      default: true,
    },
    approving: {
      type: Boolean,
      default: false,
    },
  },
  data(vm) {
    return {
      remarks: '',
      vatRate: VAT,
      ...Validations,
      submitting: false,
      isValid: false,
      confirmGarage: false,
      confirmApprover: false,
      claimAnalyst: null,
      bookingId: vm.$route.params.bookingId || vm.booking.id,
    }
  },
  mixins: [biddingFormMixin],
  computed: {
    parts() {
      return this.scanObject(this.garageInfo, 'biddingForm.repairs', [])
    },
    photos() {
      return this.scanObject(this.booking, 'biddingForm.photos', [])
    },
    canAuthorize() {
      return !(this.confirmGarage && !!this.claimAnalyst)
    },
    ...mapState({
      user: (state) => state.auth.user,
      claimAnalysts: (state) => {
        if (state.users) {
          return state.users
            .filter(
              (user) =>
                user.role === 'claimOfficer' || user.role === 'claimAnalyst'
            )
            .map((analyst) => ({
              ...analyst,
              full_name: `${analyst.fname} ${analyst.lname}`,
            }))
        } else {
          return []
        }
      },
    }),
  },
  beforeMount() {
    if (this.isGarage) {
      this.$store.dispatch('fetchUser')
    }
  },
  methods: {
    assignBid() {
      this.submitting = true
      const payload = {
        bookingId: this.bookingId,
        garageId: this.garageInfo.garage.id,
        payload: {
          authorizer: {
            email: this.user.email,
            fullName: this.user['custom:name'],
          },
          analyst: {
            email: this.claimAnalyst.email_address,
            fullName: this.claimAnalyst.full_name,
            title: this.claimAnalyst.role,
          },
          totalAmount: this.grandTotal,
          analystComment: this.remarks,
        },
      }

      this.$store.dispatch('garage/assignWinningBid', payload).then(() => {
        this.submitting = false
        this.confirmGarage = false
        this.claimAnalyst = null
        this.$router.push({ name: 'BidsSubmitted' })
      })
    },
  },
  filters: {
    formatCurrency,
    formatToHuman,
  },
}
</script>
